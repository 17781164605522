'use client'

import type { FC } from 'react'
import type { BoxProps, StackProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { IGrandSection } from 'ui'
import { GrandSection } from 'ui'
import ContactPure from './ContactPure'
import type { IContactPureProps } from './ContactPure'

export type IContactProps = IGrandSection &
  Pick<
    IContactPureProps,
    | 'apiPath'
    | 'product'
    | 'onSuccess'
    | 'customDescriptionPlaceholder'
    | 'submitButtonProps'
    | 'withDropZone'
    | 'formVariant'
  > & {
    containerProps?: BoxProps
    formContainerProps?: StackProps
  }

const Contact: FC<IContactProps> = ({
  apiPath,
  product,
  containerProps,
  withDropZone,
  customDescriptionPlaceholder,
  onSuccess,
  submitButtonProps,
  formVariant,
  formContainerProps,
  ...props
}) => {
  return (
    <GrandSection {...props}>
      <Box {...containerProps} w="full">
        <ContactPure
          {...{
            apiPath,
            product,
            withDropZone,
            customDescriptionPlaceholder,
            onSuccess,
            submitButtonProps,
            formVariant,
            ...formContainerProps
          }}
        />
      </Box>
    </GrandSection>
  )
}

export default Contact
