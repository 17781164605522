import type { FC } from 'react'
import { ListItem, UnorderedList, VStack } from '@chakra-ui/react'
import type { FooterItem } from 'ecosystem'
import { hrefFromAppPaths } from 'shared-utils'
import GrandHeading from '../../common/GrandHeading'
import GrandText from '../../common/GrandText'
import { GrandLink } from '../../common'
import { useAppPaths } from '../../hooks'

interface IFooterColumn {
  item: FooterItem
}

const FooterColumn: FC<IFooterColumn> = ({ item }) => {
  const appPaths = useAppPaths()

  return (
    <VStack
      className="footer-column"
      alignItems={{ base: 'flex-start' }}
      alignSelf="flex-start"
      flexGrow={1}>
      <GrandHeading
        className="footer-column__section-title"
        as="h2"
        containerProps={{
          my: 0
        }}
        fontSize={['lg', 'xl']}
        textAlign="left"
        title={item.sectionTitle.toUpperCase()}
        width={['100%']}
      />
      <UnorderedList listStyleType="none" p={0} spacing={1} className="footer-column__items">
        {item.items.map((i, index) => (
          // eslint-disable-next-line react/no-array-index-key -- No reliable id
          <ListItem key={index}>
            <VStack alignItems="flex-start" fontSize={['xs', 'sm']} spacing={1}>
              {i.label ? (
                <GrandText color="text.default" fontWeight="bold" w="auto">
                  {i.label}
                </GrandText>
              ) : null}

              {i.text && i.pathObj ? (
                <GrandLink
                  _focus={{ outline: 'none' }}
                  aria-label={i.text}
                  href={hrefFromAppPaths(i.pathObj, appPaths)}
                  isExternal={i.isExternal}>
                  {i.text}
                </GrandLink>
              ) : (
                <GrandText w="auto">{i.text}</GrandText>
              )}
            </VStack>
          </ListItem>
        ))}
      </UnorderedList>
    </VStack>
  )
}

export default FooterColumn
