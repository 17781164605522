'use client'
import type { FC, ReactElement } from 'react'
import { useMemo } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Box, Flex, HStack, StackDivider } from '@chakra-ui/react'
import { usePathname } from 'next/navigation'
import HoverableComponent from '../../common/HoverableComponent'
import GrandIconButton from '../../common/GrandIconButton'
import GrandNextLink from '../../common/GrandNextLink'
import type { MenuItem } from './MenuItem'
import MenuItemComponent from './MenuItem'
import ContentContainer from './ContentContainer'

export interface NavBarAction {
  id: number
  component: ReactElement
  showsOnMobile: boolean
}

export interface TopNavContentProps extends FlexProps {
  menuItems: { categories: MenuItem[]; other: MenuItem[] }
  navbarActions: NavBarAction[]
  logo: ReactElement
  onMenuHover: (isInside: boolean) => void
  onMenuToggle: (menuItems: MenuItem[]) => void
  toggleIcon: ReactElement
}

const TopNavContent: FC<TopNavContentProps> = ({
  onMenuHover,
  onMenuToggle,
  toggleIcon,
  menuItems,
  navbarActions,
  logo,
  ...props
}) => {
  const pathname = usePathname()

  const menuItemsFull = useMemo(() => {
    const mi = [...menuItems.categories, ...menuItems.other]
    // removes non-serializable values so we can pass then through extraArgs
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Intentional destructue
    return mi.map(({ onClick, onHoverLeave, onHoverEnter, ...rest }) => ({
      ...rest
    }))
  }, [menuItems])

  const handleMenuToggle = () => {
    onMenuToggle(menuItemsFull)
  }

  return (
    <Flex w="100%" {...props}>
      <ContentContainer
        direction="column"
        divider={<StackDivider />}
        justify="center"
        overflow="hidden"
        py={0}
        spacing={0}>
        <HoverableComponent alignItems="center" display="flex" onHover={onMenuHover}>
          <HStack align="center" flex={1} p={1} w="full">
            <Flex
              alignItems="center"
              display={{
                base: 'flex',
                lg: 'none'
              }}
              flex={1}>
              <GrandIconButton
                aria-label="open-menu"
                bg="none"
                color="var(--chakra-colors-component-TopNavContent-hamburger-color, var(--chakra-colors-primary))"
                icon={toggleIcon}
                onClick={handleMenuToggle}
                shadow="none"
                size="sm"
              />
            </Flex>

            <Flex
              cursor="default"
              flex={2}
              justifyContent={{
                base: 'center',
                lg: 'flex-start'
              }}>
              <GrandNextLink aria-label="home" cursor="pointer" href="/">
                {logo}
              </GrandNextLink>
            </Flex>

            <HStack
              align="center"
              display={{
                base: 'flex',
                md: 'flex'
              }}
              flex={1}
              justify="flex-end"
              spacing={4}>
              {navbarActions.map(({ component, id, showsOnMobile }) => (
                <Box
                  display={{
                    base: showsOnMobile ? 'initial' : 'none',
                    md: 'initial'
                  }}
                  key={id}>
                  {component}
                </Box>
              ))}
            </HStack>
          </HStack>
        </HoverableComponent>

        <HStack
          display={{
            base: 'none',
            lg: 'flex'
          }}
          divider={<StackDivider alignSelf="center" h="50%" />}
          fontWeight="medium"
          justify="flex-start"
          py={0}
          spacing={0}
          textAlign="center"
          w="full">
          <HStack spacing={0}>
            {menuItems.categories.map((item) => (
              <MenuItemComponent
                key={item.id}
                {...item}
                isCurrent={!!pathname?.includes(item.path)}
                text={item.text.toUpperCase()}
              />
            ))}
          </HStack>

          <HStack>
            {menuItems.other.map((item) => (
              <MenuItemComponent
                key={item.id}
                {...item}
                isCurrent={pathname === item.path}
                text={item.text.toUpperCase()}
              />
            ))}
          </HStack>
        </HStack>
      </ContentContainer>
    </Flex>
  )
}

export default TopNavContent
